.renderedServiceTicketContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
}

.renderedServiceTicket_DescriptionContainer, .renderServiceTicket_ButtonContainer{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.renderedServiceTicket_DescriptionContainer{
    text-align: left;
}

.renderedServiceTicket_ImageContainer, .renderedServiceTicket_ExtraInfoContainer{
    width: 50%;
}

.serviceTicketImage{
    max-width: 150px
}

.renderedServiceTicket_ExtraInfoContainer_Age, 
.renderedServiceTicket_ExtraInfoContainer_Status, 
.renderedServiceTicket_DescriptionContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}

.renderServiceTicket_ButtonContainer{
    display: flex;
    justify-content: center;
}

.renderServiceTicketButton{
    width: 150px
}