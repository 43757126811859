.surveyHeaderContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    margin: 10px;
    font-size: 20px;
}

.surveyHeaderLogoContainer{
    padding-left: 10px;
    padding-right: 10px;
    width: 150px;
    display: flex; /* allows child width to be 100% to grow and shrink image to fit */
    align-items: center;
    justify-content: center;
}

.groupLogo {
    width: 100%;
}

.surveyHeaderTextContainer{
    flex-grow: 1;
}

@media(min-aspect-ratio: 16/10) {

    .surveyHeaderLogoContainer{
        width: 15%;
    }

}