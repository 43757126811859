.serviceTicketClockInContainer{
    background-color: #ffffff;
    height: 100%;
    display:flex;
    flex-direction: column;
}

.serviceTicketSearchInfoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.serviceTicketInfoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.individualServiceTicketContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    border-radius: 20px;
    width: 95%;
    border: 1px solid black;
    margin-bottom: 25px;
}

.serviceTicketSearchMessage{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid black;
    border-radius: 10px;
}

@media(min-aspect-ratio: 16/10) {

    .serviceTicketSearchInfoContainer{
        margin-top: 20px;
        margin-bottom: 20px;
    }

}